import { useRouter } from 'next/router';
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import { ArticlePage, CollectionTeaserPage, LandingPage, Layout, Loading } from '@components';
import { getMarketingRedirect, getPage, getPageSlugs } from '@services/contentful';
import { getGlobalProps } from '@utils/get-global-props';
import { withLocalizedPaths } from '@utils/locales';

const Pages = ({ blocks, pageType, title, seoMetadata }: InferGetStaticPropsType<typeof getStaticProps>) => {
	const router = useRouter();
	const seo = {
		title,
		seoMetadata,
	};

	if (pageType === 'Article') return <ArticlePage blocks={blocks} {...seo} />;
	if (pageType === 'Landing Page') return <LandingPage blocks={blocks} {...seo} />;
	if (pageType === 'Teaser Page') return <CollectionTeaserPage collection={blocks[0]} {...seo} />;
	if (router.isFallback) return <Loading />;
	return null;
};

export const getStaticProps: GetStaticProps = async ({ locale, params }) => {
	try {
		const slug = Array.isArray(params.slug) ? params.slug[0] : params.slug;
		const { pageType: marketingPageType, redirectTo } = await getMarketingRedirect(slug);
		if (marketingPageType === 'Redirect') {
			return {
				redirect: { destination: redirectTo, permanent: false },
			};
		}

		const globalProps = await getGlobalProps({ locale });
		const pageData = await getPage({
			locale,
			slug,
			contentType: 'pages',
		});
		if (!pageData) {
			return { notFound: true };
		}

		const { blocks, pageType, title, seoMetadata, metadata } = pageData;
		const locales = metadata.tags
			.filter(tag => tag.sys.id.startsWith('only'))
			.map(tag => `en-${tag.sys.id.slice(4).toUpperCase()}`);
		if (locales.length > 0 && !locales.includes(locale)) {
			return {
				notFound: true,
				revalidate: 600,
			} as const;
		}

		return {
			props: {
				...globalProps,
				blocks,
				title,
				pageType,
				seoMetadata,
			},
		};
	} catch (error) {
		console.error(`SSG error in ${params?.slug}: ${error}`);
		return {
			notFound: true,
			revalidate: 600,
		} as const;
	}
};

export const getStaticPaths: GetStaticPaths = withLocalizedPaths(async () => {
	const pages = await getPageSlugs('pages');

	return {
		paths: process.env.NEXT_PUBLIC_SKIP_PATHGEN
			? []
			: pages.map(({ slug, locale }) => {
					return {
						params: { slug },
						locale: locale,
					};
				}),
		fallback: true,
	};
});

Pages.getLayout = function getLayout(page, pageProps) {
	return <Layout pageProps={pageProps}>{page}</Layout>;
};

export default Pages;
